import React from 'react';

import IntlMessages from 'util/IntlMessages';

import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

import CircularProgress from "../CircularProgress/index";

class Camera extends React.Component {
    constructor(props){
        super(props);
        this.videoRef = React.createRef();
        this.canvas =  React.createRef();
        this.state = {
            alert : false,
            alertText : '',
            devices : [],
            selectedDevice : null,
            file : null,
            loader : false
        }
    }
    
    currentStream;
    
    componentDidMount = () => {
        this.startCamera();
    }
    
    startCamera = () => {
        console.log("Mounted");
        if (typeof this.currentStream !== 'undefined') {
            this.stopMediaTracks(this.currentStream);
        }
        const videoConstraints = {};
        if (this.state.selectedDevice === null) {
        videoConstraints.facingMode = 'environment';
        } else {
        videoConstraints.deviceId = { exact: this.state.selectedDevice };
        }
        const constraints = {
        video: videoConstraints,
        audio: false
        };
        navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.currentStream = stream;
          this.videoRef.current.srcObject = stream;
          return navigator.mediaDevices.enumerateDevices();
        })
        .then(this.gotDevices)
        .catch(error => {
          console.error(error);
        });
    }
    
    stopMediaTracks = (stream) => {
      stream.getTracks().forEach(track => {
        track.stop();
      });
    }

    gotDevices = (mediaDevices) => {
      //select.innerHTML = '';
      //select.appendChild(document.createElement('option'));
      let count = 1;
      let devices = [];
      mediaDevices.forEach(mediaDevice => {
        if (mediaDevice.kind === 'videoinput') {
          //const option = document.createElement('option');
          //option.value = mediaDevice.deviceId;
          const label = mediaDevice.label || `Camera ${count++}`;
          //const textNode = document.createTextNode(label);
          //option.appendChild(textNode);
          //select.appendChild(option);
          devices.push({id : mediaDevice.deviceId, label: label});
        }
      });
      this.setState({devices: devices});
    }
    
    snap = () => {
        this.canvas.current.width = this.videoRef.current.offsetWidth;
        this.canvas.current.height = this.videoRef.current.offsetHeight;
        this.canvas.current.getContext('2d').drawImage(this.videoRef.current, 0, 0, this.videoRef.current.offsetWidth, this.videoRef.current.offsetHeight);
        
        let dataURL = this.canvas.current.toDataURL();
        let blobBin = atob(dataURL.split(',')[1]);
        let array = [];
        for(let i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
        }
        let blob=new Blob([new Uint8Array(array)], {type: 'image/jpg'});
        this.setState({file : new File([blob], 'image.jpg')});
        this.stopMediaTracks(this.currentStream);
    }
    
    closeCamera = () => {
        this.stopMediaTracks(this.currentStream);
        this.props.handleClose();
    }
    
    cancel = () => {
        this.setState({file : null}, function(){this.startCamera();});
    }
    
    addPicture = () => {
        this.setState({loader:true});
        this.stopMediaTracks(this.currentStream);
        this.props.addPicture(this.state.file);
    }
	
	render() {
		return (
            <div>
                <Dialog
              open={this.props.open}
              onClose={this.props.handleClose}
              aria-labelledby="form-dialog-title"
              fullWidth={true}
              maxWidth="xl"
            >
              <DialogTitle id="form-dialog-title"><IntlMessages id="pages.camera"/></DialogTitle>
              <DialogContent>
                <Grid container spacing={16}>
                    <Grid item xs={12}>
                        {this.state.loader && <CircularProgress/>}
                        <video ref={this.videoRef} id="video" autoPlay playsInline style={{display:(this.state.file==null?"block":"none"), width:"100%"}}></video>
                        <canvas ref={this.canvas} id="canvas" style={{display:(this.state.file!=null?"block":"none")}}></canvas>
                    </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.closeCamera} color="primary">
                  <IntlMessages id="pages.camera.close"/>
                </Button>
                {this.state.file==null && !this.state.loader && <Button onClick={this.snap} color="primary">
                  <IntlMessages id="pages.camera.take_picutre"/>
                </Button>}
                {this.state.file!=null && !this.state.loader && <Button onClick={this.cancel} color="primary">
                  <IntlMessages id="pages.camera.cancel"/>
                </Button>}
                {this.state.file!=null && !this.state.loader && <Button onClick={this.addPicture} color="primary">
                  <IntlMessages id="pages.camera.add"/>
                </Button>}
              </DialogActions>
            </Dialog>
            
            <Dialog
          open={this.state.alert}
          onClose={this.handleCloseAlert}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Attenzione"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {this.state.alertText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseAlert} color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        </div>
		);
	}
};

export default Camera;

