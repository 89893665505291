import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import Footer from 'components/Footer';
//import Tour from '../components/Tour/index';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import {isIOS, isMobile} from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import TopNav from 'components/TopNav';

class App extends React.Component {

  render() {
    const {match, drawerType, navigationStyle, horizontalNavPosition} = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height')
    }
    else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height')
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
          {/*<Tour/>*/}

        <Sidebar/>
        <div className="app-main-container">
          <div
            className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
            <TopNav styleName="app-top-header"/>}
            <Header/>
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
            <TopNav/>}
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
					<Route path={`${match.url}/sample-page`}
                         component={asyncComponent(() => import('./routes/SamplePage'))}/>
                    <Route path={`${match.url}/home`}
                         component={asyncComponent(() => import('./routes/HomePage'))}/>
                    <Route path={`${match.url}/settings`}
                         component={asyncComponent(() => import('./routes/Settings'))}/>
					<Route path={`${match.url}/companies`}
                         component={asyncComponent(() => import('./routes/Companies'))}/>
                    <Route path={`${match.url}/projects`}
                         component={asyncComponent(() => import('./routes/Projects'))}/>
					<Route path={`${match.url}/users`}
                         component={asyncComponent(() => import('./routes/Users'))}/>
                    <Route path={`${match.url}/teams`}
                         component={asyncComponent(() => import('./routes/Teams'))}/>
					<Route path={`${match.url}/certificates`}
                         component={asyncComponent(() => import('./routes/Certificates'))}/>
					<Route path={`${match.url}/medicals`}
                         component={asyncComponent(() => import('./routes/Medicals'))}/>
					<Route path={`${match.url}/jobs`}
                         component={asyncComponent(() => import('./routes/Jobs'))}/>
					<Route path={`${match.url}/activities`}
                         component={asyncComponent(() => import('./routes/Activities'))}/>
					<Route path={`${match.url}/procedures`}
                         component={asyncComponent(() => import('./routes/Procedures'))}/>
                    <Route path={`${match.url}/inspections`}
                         component={asyncComponent(() => import('./routes/Inspections'))}/>
                    <Route path={`${match.url}/surveys`}
                         component={asyncComponent(() => import('./routes/Surveys'))}/>
                    <Route path={`${match.url}/trainings`}
                         component={asyncComponent(() => import('./routes/Trainings'))}/>
                    <Route path={`${match.url}/non-compliances`}
                         component={asyncComponent(() => import('./routes/NonCompliances'))}/>
                    <Route path={`${match.url}/incidents`}
                         component={asyncComponent(() => import('./routes/Incidents'))}/>
                    <Route path={`${match.url}/environments`}
                         component={asyncComponent(() => import('./routes/Environments'))}/>
                    <Route path={`${match.url}/equipment`}
                         component={asyncComponent(() => import('./routes/Equipment'))}/>
                    <Route path={`${match.url}/injuries`}
                         component={asyncComponent(() => import('./routes/Injuries'))}/>
                    <Route path={`${match.url}/permits`}
                         component={asyncComponent(() => import('./routes/Permits'))}/>
                    <Route path={`${match.url}/ppes`}
                         component={asyncComponent(() => import('./routes/Ppes'))}/>
                    <Route path={`${match.url}/works`}
                         component={asyncComponent(() => import('./routes/Works'))}/>
                <Route component={asyncComponent(() => import('components/Error404'))}/>
              </Switch>
            </div>
            <Footer/>
          </main>
        </div>
      </div>
    );
  }
}


const mapStateToProps = ({settings}) => {
  const {drawerType, navigationStyle, horizontalNavPosition} = settings;
  return {drawerType, navigationStyle, horizontalNavPosition}
};
export default withRouter(connect(mapStateToProps)(App));